import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import { BetterThanOther, MultiColumnList } from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Education extends Component {
  render() {
    const education = pageContent.subPage.education;

    return (
      <Layout>
        <PageHeader image={education.headerImage} />

        <Heading
          title={education.title}
          overview={education.overview}
          noHeadingIcon={true}
        />

        <BetterThanOther content={education.betterThanOther} />

        <BetterThanOther
          titleNoHighlight={true}
          content={education.serviceCatalogues}
          footerText={education.serviceCatalogues.footerText}
        />

        <MultiColumnList content={education.solutions} />

        <MultiColumnList
          listStyle={'check'}
          titleTextHighlighted={true}
          content={education.benefits}
        />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default Education;
